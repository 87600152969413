import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, NavigationError } from '@angular/router';
import { AuthService, CurrentUserWithout2FADetails, IAuthDetails } from './authService';
import { UniHttp } from '../framework/core/http/http';
import { ToastService } from '../framework/uniToast/toastService';
import { LicenseEntityStatus, BankIntegrationAgreement } from '@app/unientities';
import { ConfirmActions, IModalOptions } from '@uni-framework/uni-modal/interfaces';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import {
    UniModalService,
    UserLicenseAgreementModal,
    LicenseAgreementModal,
    CompanyActionsModal,
    ConfirmModal,
    BrunoBankIdOnboardingModal,
    ProductConsentModal,
    AdminLackOfAccessModal,
} from '@uni-framework/uni-modal';

import { ChatBoxService } from './components/layout/chat-box/chat-box.service';
import { theme, THEMES } from 'src/themes/theme';
import { Logger } from '@uni-framework/core/logger';
import { environment } from 'src/environments/environment';
import { BankAgreementServiceProvider } from './models/autobank-models';
import { ElsaUserLicenseType } from './models';

import { initChartJS } from './chartjs-setup';
import { fromEvent, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrunoOnboardingService } from './services/bank/BrunoOnboardingService';
import { ErrorService } from './services/common/errorService';
import { StatisticsService } from './services/common/statisticsService';
import { UserRoleService } from './services/common/userRoleService';
import { UserService } from './services/common/userService';
import { ElsaCustomersService } from './services/elsa/elsaCustomersService';
import { ElsaPurchaseService } from './services/elsa/elsaPurchasesService';
import { CelebrusService } from './services/analytics-services/celebrus-service';
import { CMSService } from './services/cms/cms-service';
import { APP_METADATA } from 'src/environments/metadata';
import { AnalyticsService } from './services/analytics-services/analytics.service';

const HAS_ACCEPTED_USER_AGREEMENT_KEY = 'has_accepted_user_agreement';
const DONT_ASK_SELF_GRANT_ACCESS_ACCOUNTANT_ADMIN_KEY = 'dont-ask-self-grant-access-accountant-admin';
const DONT_ASK_PRODUCT_PURCHASE_AGAIN_KEY = 'dont-ask-product-purchase-again';

@Component({
    selector: 'uni-app',
    templateUrl: './app.html',
})
export class App {
    private licenseAgreementModalOpen: boolean;
    private userlicenseModalOpen: boolean;
    private productConsentModalOpen: boolean;
    private adminLackOfAccessModalOpen: boolean;

    isAuthenticated: boolean;
    isOnInitRoute: boolean;
    isPendingApproval: boolean;
    isBankCustomer: boolean;
    hasActiveContract: boolean;
    bankName: string;
    licenseExpired: boolean;
    expiredEntity: string;
    supportPageUrl: string;
    isMissingRoles: boolean;
    isStandardUserMissingProducts: boolean;
    isEikaEnvironment: boolean;

    // changing it to .includes('unieconomy.no') will affect dev, test, rc and so on
    redirectToUnimicro =
        location.host === 'unieconomy.no' ||
        location.host === 'sk.unieconomy.no' ||
        location.host === 'smb.unieconomy.no';

    constructor(
        private titleService: Title,
        private authService: AuthService,
        private modalService: UniModalService,
        private toastService: ToastService,
        private uniHttp: UniHttp,
        private errorService: ErrorService,
        private browserStorage: BrowserStorageService,
        private router: Router,
        private statisticsService: StatisticsService,
        public chatBoxService: ChatBoxService,
        private brunoOnboardingService: BrunoOnboardingService,
        private elsaCustomerService: ElsaCustomersService,
        private elsaPurchaseService: ElsaPurchaseService,
        private userService: UserService,
        private userRoleService: UserRoleService,
        private celebrusService: CelebrusService,
        private analytics: AnalyticsService,
        private route: ActivatedRoute,
        private logger: Logger,
        private cms: CMSService,
    ) {
        if (!this.titleService.getTitle()) {
            const title = theme.appName;
            this.titleService.setTitle(title);
        }

        this.celebrusService.init();
        this.analytics.init();
        this.logger.init();

        // prohibit dropping of files unless otherwise specified
        document.addEventListener(
            'dragover',
            function (event: any) {
                if (event.toElement && event.toElement.className === 'uni-image-upload') {
                    event.preventDefault();
                    event.dataTransfer.dropEffect = 'copy';
                } else {
                    event.preventDefault();
                    event.dataTransfer.dropEffect = 'none';
                }
            },
            false,
        );

        document.addEventListener(
            'drop',
            function (event) {
                event.preventDefault();
            },
            false,
        );

        authService.getPublicSettings();

        authService.authentication$.subscribe((authDetails) => {
            this.isAuthenticated = !!authDetails.user;
            this.isEikaEnvironment = theme.theme === THEMES.EIKA;
            this.hasActiveContract = authDetails.hasActiveContract;
            this.resetDeactivateFunctionsFlags();
            if (this.isAuthenticated) {
                this.cms.initCMSComponents();
                this.toastService.clear();
                this.supportPageUrl = this.authService.publicSettings?.SupportPageUrl;

                if (this.hasAnyExpiredLicense(authDetails)) {
                    return;
                }

                if (authDetails.user.License.Company.StatusCode !== LicenseEntityStatus.Pending) {
                    this.handleProductRestrictAccessScenarios(authDetails);
                }

                this.isMissingRoles = !authDetails.user['Permissions']?.length;
                if (this.isMissingRoles) {
                    return;
                }

                if (
                    (theme.theme === THEMES.SR || theme.theme === THEMES.EIKA) &&
                    authDetails.user.License.Company.StatusCode === LicenseEntityStatus.Pending
                ) {
                    if (theme.theme === THEMES.SR) {
                        this.elsaCustomerService.getByContractID(authDetails.user.License.Company.ContractID).subscribe(
                            (customer) => {
                                if (!customer?.IsBankCustomer) {
                                    this.bankName = this.authService.publicSettings?.BankName || 'SpareBank 1';
                                }
                                this.isBankCustomer = !!customer?.IsBankCustomer;
                            },
                            (err) => console.error(err),
                        );
                    }
                    this.isPendingApproval = true;
                    return;
                }

                const shouldShowLicenseDialog =
                    !this.licenseAgreementModalOpen &&
                    !authDetails.isDemo &&
                    !this.hasAcceptedCustomerLicense(authDetails.user);

                if (shouldShowLicenseDialog) {
                    this.licenseAgreementModalOpen = true;
                    this.modalService
                        .open(LicenseAgreementModal, {
                            hideCloseButton: environment.useProdMode,
                            closeOnClickOutside: !environment.useProdMode,
                            closeOnEscape: !environment.useProdMode,
                        })
                        .onClose.subscribe(() => (this.licenseAgreementModalOpen = false));
                }

                if (
                    theme.theme !== THEMES.EXT02 &&
                    !this.userlicenseModalOpen &&
                    !this.hasAcceptedUserLicense(authDetails.user)
                ) {
                    this.showUserLicenseModal();
                }

                if (!this.productConsentModalOpen) {
                    // flag to stop the modal opening twice
                    this.productConsentModalOpen = true;
                    this.elsaPurchaseService.checkPurchasesWithConsentRequired(authDetails).subscribe((data) => {
                        if (data) {
                            this.modalService
                                .open(ProductConsentModal, {
                                    data,
                                    hideCloseButton: true,
                                })
                                .onClose.subscribe(() => (this.productConsentModalOpen = false));
                        } else {
                            this.productConsentModalOpen = false;
                        }
                    });
                }

                if (theme.theme === THEMES.EXT02 && !authDetails.activeCompany.IsTest) {
                    const initOpenBankIDModal =
                        localStorage.getItem('init-open-bankid-modal') ===
                        this.authService.activeCompany.OrganizationNumber;

                    if (initOpenBankIDModal) {
                        localStorage.removeItem('init-open-bankid-modal');
                        this.userService.getConnectedExternalProviders().subscribe((providers) => {
                            if (!!providers?.find((p) => p.loginProvider === 'bankiddnbsso')) {
                                this.modalService.open(BrunoBankIdOnboardingModal, {
                                    data: { isRedirectedFromBankID: true },
                                });
                                return;
                            }
                        });
                    }

                    this.brunoOnboardingService
                        .getAgreement(BankAgreementServiceProvider.Bruno)
                        .subscribe((fileAgreement: BankIntegrationAgreement) => {
                            if (!browserStorage.getItemFromCompany('isNotInitialLogin')) {
                                this.showInitialBrunoLoginModal();
                                browserStorage.setItemOnCompany('isNotInitialLogin', true);
                            }

                            if (
                                this.brunoOnboardingService.hasNewAccountInfo(fileAgreement) &&
                                !browserStorage.getItemFromCompany('notShowConnectAccoutsPopUpModal')
                            ) {
                                this.brunoOnboardingService.connectBankAccounts(true).subscribe();
                            }
                        });
                }
            }
        });

        fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
            const keyCode = event.which || event.keyCode;
            const character = String.fromCharCode(keyCode);
            if (event.ctrlKey && event.altKey && character === 'B') {
                this.router.navigateByUrl('/bureau');
            }
        });

        this.checkForInitRoute();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.checkForInitRoute();
            }

            if (event instanceof NavigationError) {
                this.checkForChunkLoadingError(event);
            }
        });

        initChartJS();
    }

    private checkForChunkLoadingError(event: NavigationError) {
        try {
            const error: string = event.error.message || event.error.toString();
            if (error.toLowerCase().includes('failed to fetch dynamically imported module')) {
                // Avoid infinite reload loop in cases where the initial reload didn't solve the problem
                const hasReloadedOnVersion = sessionStorage.getItem('reloaded_on_version');
                if (!hasReloadedOnVersion || hasReloadedOnVersion !== APP_METADATA.APP_VERSION) {
                    sessionStorage.setItem('reloaded_on_version', APP_METADATA.APP_VERSION);
                    window.location.href = window.location.href.split('/#/')[0] + '/#' + event.url;
                    location.reload();
                }
            }
        } catch (e) {}
    }

    private hasAnyExpiredLicense(authDetails: IAuthDetails): boolean {
        // temporary: this makes sure we only block non UE environments
        const isNotUE = theme.theme !== THEMES.UE;

        if (!authDetails.hasActiveContract) {
            if (authDetails.isDemo) {
                const isActivation = this.route.snapshot.queryParams['contractActivation'] === 'true';
                this.router.navigateByUrl(`/contract-activation${isActivation ? '?contractActivation=true' : ''}`);
                // it's expired, but we don't want to block an expired demo
                this.licenseExpired = false;
                return false;
            } else {
                this.expiredEntity = 'lisensen';
                this.licenseExpired = isNotUE; // true;
                return isNotUE; // true;
            }
        }

        if (!authDetails.hasActiveUserLicense) {
            this.expiredEntity = 'brukerlisensen';
            this.licenseExpired = isNotUE; // true;
            return isNotUE; // true;
        }

        if (!authDetails.hasActiveCompanyLicense) {
            this.expiredEntity = 'selskapslisensen';
            this.licenseExpired = isNotUE; // true;
            return isNotUE; // true;
        }

        this.licenseExpired = false;
        return false;
    }

    private resetDeactivateFunctionsFlags() {
        this.isMissingRoles = false;
        this.isStandardUserMissingProducts = false;
        this.licenseExpired = false;
        this.isPendingApproval = false;
    }

    private checkForInitRoute() {
        if (this.router.url) {
            this.isOnInitRoute = this.router.url.startsWith('/init');
        }
    }

    private hasAcceptedCustomerLicense(user: CurrentUserWithout2FADetails): boolean {
        return user && user.License && user.License.CustomerAgreement
            ? !!user.License.CustomerAgreement.HasAgreedToLicense || user.License.CustomerAgreement.AgreementId === 0
            : true;
    }

    private hasAcceptedUserLicense(user: CurrentUserWithout2FADetails): boolean {
        return user && user.License && user.License.UserLicenseAgreement
            ? !!user.License.UserLicenseAgreement.HasAgreedToLicense ||
                  user.License.UserLicenseAgreement.AgreementId === 0
            : true;
    }

    goToExternalSignup() {
        if (theme.theme === THEMES.SR && this.authService.publicSettings?.BankCustomerUrl) {
            let url = this.authService.publicSettings.BankCustomerUrl;
            this.statisticsService
                .GetAllUnwrapped('model=CompanySettings&select=OrganizationNumber as OrganizationNumber')
                .subscribe(
                    (settings) => {
                        const orgNumber = settings && settings[0] && settings[0].OrganizationNumber;
                        if (orgNumber) {
                            url += `?bm-orgNumber=${orgNumber}`;
                        }

                        window.open(url, '_blank');
                    },
                    () => window.open(url, '_blank'),
                );
        }
    }

    private showUserLicenseModal() {
        this.userlicenseModalOpen = true;
        this.modalService
            .open(UserLicenseAgreementModal, {
                hideCloseButton: environment.useProdMode,
                closeOnClickOutside: !environment.useProdMode,
                closeOnEscape: !environment.useProdMode,
            })
            .onClose.subscribe((response) => {
                this.userlicenseModalOpen = true;
                if (response === ConfirmActions.ACCEPT) {
                    this.browserStorage.setItem(HAS_ACCEPTED_USER_AGREEMENT_KEY, true);
                    this.uniHttp
                        .asPOST()
                        .usingBusinessDomain()
                        .withEndPoint('users?action=accept-UserLicenseAgreement')
                        .send()
                        .pipe(map((res) => res.body))
                        .subscribe(
                            () => {},
                            (err) => this.errorService.handle(err),
                        );
                } else if (environment.useProdMode) {
                    this.authService.clearAuthAndGotoLogin();
                }
            });
    }

    private showInitialBrunoLoginModal() {
        if (
            this.hasLoggedInWithBankID() &&
            this.bankIdAgreementFailed(this.authService.activeCompany.OrganizationNumber)
        ) {
            this.showAgreementFailedModal();
            return;
        }

        this.modalService.open(CompanyActionsModal, { hideCloseButton: true });
    }

    bankIdAgreementFailed(orgnr: string): boolean {
        const storage = localStorage.getItem('bankid-agreement-failed')?.split(',') || [];
        if (storage[0] !== orgnr) {
            return false;
        }

        if (storage.length > 0 && storage[1] === 'true') {
            localStorage.removeItem('bankid-agreement-failed');
            return true;
        }

        return false;
    }

    showAgreementFailedModal() {
        const storageText = localStorage.getItem('bankid-agreement-failed-text')?.split('|') || [];

        let header = 'Kommunikasjonsproblemer';
        let message = 'Vi har litt kommunikasjonsproblemer nå. Prøv igjen senere. Du kan også kontakte oss på chat.';

        if (storageText.length > 0) {
            header = storageText[0];
            message = storageText[1];
        }

        localStorage.removeItem('bankid-agreement-failed-text');

        const errorOptions: IModalOptions = {
            header: header,
            message: message,
            footerCls: 'center',
            buttonLabels: {
                accept: 'OK',
            },
        };
        this.modalService.open(ConfirmModal, errorOptions).onClose.subscribe(() => {
            this.modalService.open(CompanyActionsModal, { hideCloseButton: true });
        });
    }

    private hasLoggedInWithBankID() {
        const jwt = this.authService.decodedToken();
        return !!jwt?.idp?.includes('bankid');
    }

    private handleProductRestrictAccessScenarios(authDetails: IAuthDetails) {
        // spaghet since it's too late to fix it properly
        const dontAsk1 = `${DONT_ASK_SELF_GRANT_ACCESS_ACCOUNTANT_ADMIN_KEY}_${authDetails.activeCompany.Key}`;
        const dontAsk2 = `${DONT_ASK_PRODUCT_PURCHASE_AGAIN_KEY}_${authDetails.activeCompany.Key}`;

        const shouldHandleAdminRoleChanged = !localStorage.getItem(dontAsk1) && !localStorage.getItem(dontAsk2);
        if (!shouldHandleAdminRoleChanged) {
            return;
        }
        // end spaghet

        // Avoid opening both role modal and product purchase modal when reloading the page from users.ts
        const isOpenRoleModalUsingParam = this.route.snapshot.queryParamMap.get('openRoleModal') === 'true';

        this.isStandardUserMissingProducts = false;
        if (authDetails?.user?.License?.UserType?.TypeID === ElsaUserLicenseType.Standard) {
            forkJoin([
                this.elsaPurchaseService.getUserActivePurchases(this.authService.currentUser.GlobalIdentity),
                this.userRoleService.hasAdminRole(this.authService.currentUser.ID),
            ]).subscribe(([purchases, isAdmin]) => {
                if (purchases?.length) return;

                if (isAdmin) {
                    const dontAskFlagKeyForCompany = `${DONT_ASK_PRODUCT_PURCHASE_AGAIN_KEY}_${authDetails.activeCompany.Key}`;
                    const dontAskAgainStandard = localStorage.getItem(dontAskFlagKeyForCompany) === 'true';
                    if (!dontAskAgainStandard && !isOpenRoleModalUsingParam && !this.adminLackOfAccessModalOpen) {
                        this.showAdminLackOfAccessModal(dontAskFlagKeyForCompany);
                    }
                } else {
                    this.isStandardUserMissingProducts = true;
                }
            });
        } else if (authDetails?.user?.License?.UserType?.TypeID === ElsaUserLicenseType.Accountant) {
            this.userRoleService.hasOnlyAdminRole(this.authService.currentUser.ID).subscribe((hasAdminRoleOnly) => {
                if (!hasAdminRoleOnly) return;

                const dontAskFlagKeyForCompany = `${DONT_ASK_SELF_GRANT_ACCESS_ACCOUNTANT_ADMIN_KEY}_${authDetails.activeCompany.Key}`;
                const dontAskAgainAccountant = localStorage.getItem(dontAskFlagKeyForCompany) === 'true';
                if (!dontAskAgainAccountant && !isOpenRoleModalUsingParam && !this.adminLackOfAccessModalOpen) {
                    this.showAdminLackOfAccessModal(dontAskFlagKeyForCompany);
                }
            });
        }
    }

    private showAdminLackOfAccessModal(flagKey: string) {
        this.adminLackOfAccessModalOpen = true;
        const options: IModalOptions = {
            data: {
                currentUserId: this.authService.currentUser.ID,
                flagKey: flagKey,
            },
            hideCloseButton: false,
            closeOnClickOutside: false,
            closeOnEscape: false,
        };
        this.modalService
            .open(AdminLackOfAccessModal, options)
            .onClose.subscribe(() => (this.adminLackOfAccessModalOpen = false));
    }
}
